import React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import img1 from '../../assets/features1.png';
import img2 from '../../assets/features2.png';
import img3 from '../../assets/features3.png';
import img4 from '../../assets/features4.png';
import css from './LandingPage.module.css';

const images = [img1, img2, img3, img4];

const SectionDetails = () => {
  const intl = useIntl();

  return (
    <div className={css.alternatingSection}>
      {[1, 2, 3, 4].map((item, index) => (
        <div key={index} className={classNames(css.row, { [css.reverse]: index % 2 !== 0 })}>
          <div className={css.imageContainer}>
            <img
              src={images[index]}
              alt={intl.formatMessage({ id: `LandingDetails.imageAlt${item}` })}
            />
          </div>
          <div className={css.textContainer}>
            <h3>{intl.formatMessage({ id: `LandingDetails.title${item}` })}</h3>
            <p>{intl.formatMessage({ id: `LandingDetails.description${item}` })}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SectionDetails;
