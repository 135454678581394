import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import css from './LandingPage.module.css';
import banner2 from '../../assets/banner2.png';

const SectionOffering = () => {
  return (
    <div className={css.sectionOffering}>
      <div className={css.bannerContainer} style={{ backgroundImage: `url(${banner2})` }}>
        <h2 className={css.bannerText}>
          <FormattedMessage id="LandingPage.offeringTitle" />
        </h2>
      </div>
    </div>
  );
};

export default SectionOffering;
