import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedMessage } from '../../util/reactIntl';
import englishStyle from './LandingPage.module.css';
import { USER_TYPE } from '../../helpers/enums';
import { NamedLink } from '../../components';

function SectionHero() {
  const css = englishStyle;

  return (
    <div className={css.heroContainer}>
      <div className={css.contentWidth}>
        <div className={css.heroContent}>
          <h1>
            <FormattedMessage id="LandingPage.heading" />
          </h1>
          <p>
            <FormattedMessage id="LandingPage.description" />
          </p>
          <div className={css.registerAs}>
            <Link className={classNames(css.individual, css.commonBtn)} to="#join">
              <FormattedMessage id="LandingPage.joinTheProgram" />
            </Link>
            <NamedLink
              name="LandingPatientPage"
              params={{ type: USER_TYPE.INDIVIDUAL }}
              className={classNames(css.individual, css.commonBtn)}
            >
              <FormattedMessage id="LandingPage.fineAnExpert" />
            </NamedLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHero;
