import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import css from './LandingPage.module.css';
const programs = [
  {
    name: 'LandingPage.programs.fertility',
    link: '#',
  },
  {
    name: 'LandingPage.programs.menopause',
    link: '#',
  },
  {
    name: "LandingPage.programs.womensHealth",
    link: '#',
  },
  {
    name: 'LandingPage.programs.pregnancy',
    link: '#',
  },
  {
    name: 'LandingPage.programs.sexualWellness',
    link: '#',
  },
];

function SectionPrograms() {
  return (
    <div className={css.programsContainer}>
      {
        programs.map((program) => (
          <button key={program.name} className={css.programButton}>
            <FormattedMessage id={program.name} />
          </button>
        ))
      }
    </div>
  );
}

export default SectionPrograms;
