import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import englishStyle from './LandingPage.module.css';

import workImg1 from '../../assets/work1-old.png';
import workImg2 from '../../assets/work2-old.png';
import howItWork1 from '../../assets/how-it-work1.png';
import howItWork2 from '../../assets/how-it-work2.png';
import howItWork3 from '../../assets/how-it-work3.png';
import howItWork4 from '../../assets/how-it-work4.png';

const howItWorkImages = [howItWork1, howItWork2, howItWork3, howItWork4];

const EnglishDescription1 = () => {
  const intl = useIntl();

  const first1HTML = intl.formatMessage({ id: 'LandingPage.first1' });
  const first2HTML = intl.formatMessage({ id: 'LandingPage.first2' });
  const first3HTML = intl.formatMessage({ id: 'LandingPage.first3' });
  const first4HTML = intl.formatMessage({ id: 'LandingPage.first4' });

  return (
    <div>
      <p dangerouslySetInnerHTML={{ __html: first1HTML }}></p>
      <p dangerouslySetInnerHTML={{ __html: first2HTML }}></p>
      <p dangerouslySetInnerHTML={{ __html: first3HTML }}></p>
      <p dangerouslySetInnerHTML={{ __html: first4HTML }}></p>
    </div>
  );
};

const EnglishDescription2 = () => {
  const intl = useIntl();

  const second1HTML = intl.formatMessage({ id: 'LandingPage.second1' });
  const second2HTML = intl.formatMessage({ id: 'LandingPage.second2' });

  return (
    <div>
      <p dangerouslySetInnerHTML={{ __html: second1HTML }}></p>
      <p dangerouslySetInnerHTML={{ __html: second2HTML }}></p>
    </div>
  );
};

function SectionHowItWorks() {
  const css = englishStyle;
  const history = useHistory();

  const goTo = () => {
    history.push(createResourceLocatorString('LandingPatientPage', routeConfiguration(), {}, {}));
  };

  const goToSection = () => (window.location.href = '#join');

  const workBlocks = [
    {
      img: workImg1,
      title: <FormattedMessage id="LandingPage.findTitle" />,
      description: <EnglishDescription1 />,
      button: (
        <button className={css.submitButton} onClick={goToSection}>
          <FormattedMessage id="LandingPage.startNow" />
        </button>
      ),
    },
    {
      img: workImg2,
      title: <FormattedMessage id="LandingPage.askTitle" />,
      description: <EnglishDescription2 />,
      button: (
        <button className={css.submitButtonGreen} onClick={goTo}>
          <FormattedMessage id="LandingPage.bookASeasson" />
        </button>
      ),
    },
  ];

  return (
    <div className={css.aboutSection}>
      {[1, 2, 3, 4].map(num => (
        <span key={num} className={css[`shape${num}`]}>
          <img src={howItWorkImages[num - 1]} alt="How it works" />
        </span>
      ))}
      <div className={css.aboutContent}>
        <div className={css.contentWidth}>
          <h1>
            <FormattedMessage id="LandingPage.howItWorks" />
          </h1>
          <div className={css.howItWorks}>
            {workBlocks.map((block, index) => (
              <div key={index} className={css.workBlock}>
                <div className={css.upperElement}>
                  <div className={css.workImg}>
                    <img src={block.img} alt="How it works" />
                  </div>
                  <h2>{block.title}</h2>
                </div>
                <div className={css.middleElement}>{block.description}</div>
                <div className={css.lowerElement}>{block.button}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHowItWorks;
