import React from 'react';
import { FormattedMessage } from 'react-intl';
import css from './LandingPage.module.css';

const topics = [
  'LandingPage.answerabout.familyPlanning',
  'LandingPage.answerabout.pregnancyAndPostpartum',
  'LandingPage.answerabout.sexualPlesure',
  'LandingPage.answerabout.menopause',
  'LandingPage.answerabout.endometriosis',
  'LandingPage.answerabout.ivfAndFamilyPlanning',
  'LandingPage.answerabout.womenHealth',
  'LandingPage.answerabout.genitalAesthetics',
  'LandingPage.answerabout.paediatrics',
  'LandingPage.answerabout.postpartumDepressionAndMentalHealth',
  'LandingPage.answerabout.genderExploration',
];

const experts = [
  'LandingPage.answerabout.obGyn',
  'LandingPage.answerabout.menopauseExpert',
  'LandingPage.answerabout.urogynecologist',
  'LandingPage.answerabout.midwive',
  'LandingPage.answerabout.sexExperts',
  'LandingPage.answerabout.embryologist',
  'LandingPage.answerabout.pelvic',
  'LandingPage.answerabout.psychologist',
  'LandingPage.answerabout.nutritionist',
  'LandingPage.answerabout.hypnotherapist',
  'LandingPage.answerabout.embryologist',
  'LandingPage.answerabout.endocrinologist',
  'LandingPage.answerabout.adoptionExpert',
  'LandingPage.answerabout.sleepCoach',
  'LandingPage.answerabout.andManyMore',
];


function SectionAnswers() {
  return (
    <div className={css.answersSection}>
      <h2><FormattedMessage id="landingPage.findAnswersAbout" /></h2>
      <div className={css.answersSectionTabs}>
        <div className={css.answersSectionTopics}>
          <h3 className={css.answersTopicTitle}><FormattedMessage id="LandingPage.topic" /></h3>
          <div className={css.answersSectionList}>
            {topics.map((topic, index) => (
              <div key={index} className={css.answerTopicItem}>
                <FormattedMessage id={topic} />
              </div>
            ))}
          </div>
        </div>
        <div className={css.answerSeparator}></div>
        <div className={css.answersSectionExperts}>
          <h3 className={css.answersExpertTitle}><FormattedMessage id="LandingPage.fromExperts" /></h3>
          <div className={css.answersSectionList}>
            {experts.map((expert, index) => (
              <div key={index} className={css.answerExpertItem}>
                <FormattedMessage id={expert} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionAnswers;
